import React from 'react';
import './RecipeItem.css';

const RecipeItem = ({ image, title, prepTime, level }) => {
  return (
    <div className="recipe-item">
      <img src={image} alt={title} className="recipe-image" />
      <div className="recipe-info">
        <h3 className="recipe-title">{title}</h3>
        <p className="recipe-time">Temps de préparation : {prepTime}</p>
        <p className="recipe-level">Niveau : {level}</p>
      </div>
    </div>
  );
};

export default RecipeItem;
