import React, { useRef, useState } from 'react';
import './VideoPlayer.css'; // Importation du CSS
import pizzaVideo from '../assets/pizza.mp4'; // Chemin correct de la vidéo

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false); // Nouvel état pour l'agrandissement
  const [isVisible, setIsVisible] = useState(true); // Nouvel état pour la visibilité

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowOverlay(false);
    } else {
      videoRef.current.pause();
      setShowOverlay(true);
    }
  };

  const handleVideoEnd = () => {
    videoRef.current.currentTime = 0;
    setShowOverlay(true);
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded); // Bascule entre agrandi et réduit
  };

  const handleCloseClick = () => {
    setIsVisible(false); // Ferme complètement la vidéo
  };

  return (
    isVisible && (
      <div className={`video-container ${isExpanded ? 'expanded' : 'small'}`}>
        <video
          ref={videoRef}
          src={pizzaVideo}
          className="video-element"
          onClick={handleVideoClick}
          onEnded={handleVideoEnd}
          playsInline
        />
        {showOverlay && (
          <div className="overlay" onClick={handleVideoClick}>
            Demo
          </div>
        )}
        <button className="expand-button" onClick={handleExpandClick}>
          {isExpanded ? 'Minimize' : 'Agrandir'}
        </button>
        <button className="close-button" onClick={handleCloseClick}>
          ✖
        </button>
      </div>
    )
  );
};

export default VideoPlayer;
