// src/components/CartDropdown.js
import React from 'react';
import './CartDropdown.css';
import { useCart } from './CartContext';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Psnhn05HW3AULdh1bqnp2xIX0PY0PjS6M3q4wU1mtC2WPs1912ZeaB9xhjK2mF0X2WNeXvIutusJHE0wOC8uDPn00QEWPos6G');

const CartDropdown = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const lineItems = cartItems.map((item) => ({
      price: item.stripePriceId,
      quantity: item.quantity,
    }));

    try {
      const result = await stripe.redirectToCheckout({
        lineItems,
        mode: 'payment',
        successUrl: window.location.origin + '/success',
        cancelUrl: window.location.origin + '/cancel',
      });

      if (result.error) {
        console.error('Erreur lors de la redirection vers Stripe:', result.error.message);
      }
    } catch (error) {
      console.error('Erreur dans handleCheckout:', error);
    }
  };

  return (
    <div className="cart-dropdown">
      <h3>Votre Panier</h3>
      {cartItems.length === 0 ? (
        <p>Votre panier est vide.</p>
      ) : (
        <ul className="cart-items-list">
          {cartItems.map((item) => (
            <li key={item.id} className="cart-item">
              {item.name} - {item.price} € x {item.quantity}
              <button className="remove-item-button" onClick={() => removeFromCart(item.id)}>
                &times;
              </button>
            </li>
          ))}
        </ul>
      )}
      <button className="checkout-button" onClick={handleCheckout}>Procéder au paiement</button>
      {cartItems.length > 0 && (
        <button className="clear-cart-button" onClick={clearCart}>
          Vider le panier
        </button>
      )}
    </div>
  );
};

export default CartDropdown;
