// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import RecipeList from './components/RecipeList'; 
import PreparationSteps from './components/PreparationSteps';
import Four from './components/Fours'; 
import Pelles from './components/Pelles'; 
import Alimentaire from './components/Alimentaire'; 
import Accessoires from './components/Accessoires'; 
import ContactPage from './components/ContactPage';
import CartSidebar from './components/CartSidebar'; // Mise à jour de l'importation
import { CartProvider } from './components/CartContext';
import './index.css';

function App() {
  return (
    <CartProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recettes" element={<RecipeList />} />
            <Route path="/preparation/:title" element={<PreparationSteps />} />
            <Route path="/fours" element={<Four />} />
            <Route path="/pelles" element={<Pelles />} />
            <Route path="/alimentaire" element={<Alimentaire />} />
            <Route path="/accessoires" element={<Accessoires />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
          <CartSidebar /> {/* Intégration de la barre latérale */}
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
