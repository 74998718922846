import React from 'react';
import './ProductCard.css';
import DeliveryEstimate from './DeliveryEstimate'; // Importer le nouveau composant

const ProductCard = ({ product, isSelected, onSelect }) => {
  return (
    <div 
      className={`product-card ${isSelected ? 'selected' : ''}`} 
      onClick={onSelect}
    >
      <div className="badge">{product.badge}</div>
      <h3>{product.title}</h3>
      
      {/* Miniature plus petite */}
      <img 
        src={product.thumbnail} 
        alt={`${product.title} Thumbnail`} 
        className="product-thumbnail" 
      />

      <div className="price-section">
        <span className="original-price">{product.originalPrice}</span>
        <span className="discounted-price">{product.price}</span>
      </div>
      <p>{product.description}</p>
      <ul>
        {product.perks.map((perk, idx) => (
          <li key={idx}>{perk}</li>
        ))}
      </ul>

      {/* Utilisation du composant DeliveryEstimate */}
      <div className="delivery-estimate">
        <DeliveryEstimate />
      </div>
    </div>
  );
};

export default ProductCard;
