import React from 'react';
import { useParams } from 'react-router-dom';
import './PreparationSteps.css';

const PreparationSteps = () => {
  const { title } = useParams(); // Récupère le titre depuis l'URL

  // Dictionnaire des étapes de préparation pour chaque recette
  const recipesSteps = {
    'pizza-copa-abricot': [
      {
        title: 'Préchauffage du Four',
        description: 'Préchauffez votre four à 350°C pendant 20 minutes.',
      },
      {
        title: 'Préparation de la Pâte',
        description: 'Étalez la pâte à pizza de manière homogène sur une surface plane.',
      },
      {
        title: 'Ajout de la Base de Crème Fraîche',
        description: 'Étalez une fine couche de crème fraîche sur la pâte pour ajouter de la douceur et de la richesse.',
      },
      {
        title: 'Ajout de la Mozzarella',
        description: 'Répartissez uniformément la mozzarella fior di latte sur la crème fraîche.',
      },
      {
        title: 'Ajout du Gorgonzola',
        description: 'Ajoutez quelques morceaux de gorgonzola DOP par-dessus la mozzarella.',
      },
      {
        title: 'Ajout de la Coppa',
        description: 'Disposez les tranches de coppa sur les fromages de manière équilibrée.',
      },
      {
        title: 'Cuisson au Four',
        description: 'Enfournez la pizza, surveillez jusqu’à ce que les bords soient dorés et que les fromages soient fondus.',
      },
      {
        title: 'Ajout des Garnitures Finales',
        description: 'À la sortie du four, garnissez la pizza avec des cuillerées de confiture d’abricot, des amandes concassées, et des feuilles de basilic frais.',
      },
    ],
    'pizza-pistache': [
      {
        title: 'Préchauffage du Four',
        description: 'Préchauffez votre four pendant 20 minutes à fond.',
      },
      {
        title: 'Préparation de la Crème de Pistache',
        description: 'Mixez les pistaches avec 150 ml d\'eau pour obtenir une pâte fine. Ajoutez le jus de citron, l\'huile d\'olive et le sel. Réservez la crème de pistache dans un bol couvert.',
      },
      {
        title: 'Préparation de la Pâte',
        description: 'Étirez la pâte sur un plan de travail fariné pour obtenir un rond de 30 cm.',
      },
      {
        title: 'Ajout de la Crème de Pistache et des Fromages',
        description: 'Étalez ⅙ de la crème de pistache, ⅙ du fromage de brebis et ⅙ de la mozzarella uniformément sur la base. Arrosez d\'un filet d\'huile d\'olive.',
      },
      {
        title: 'Cuisson au Four',
        description: 'Enfournez la pizza et surveillez la.',
      },
      {
        title: 'Ajout des Garnitures Finales',
        description: 'À la sortie du four, ajoutez des tranches de prosciutto, des zestes de citron et des pistaches concassées. Coupez la pizza en 4 et servez.',
      },
    ],
    'pizza-végétarienne': [
      {
        title: 'Préchauffage du Four',
        description: 'Préchauffez votre four à 350°C pendant 20 minutes.',
      },
      {
        title: 'Préparation de la Pâte',
        description: 'Étalez la pâte à pizza de manière homogène sur une surface plane.',
      },
      {
        title: 'Ajout de la Sauce Tomate',
        description: 'Étalez une fine couche de sauce tomate sur la pâte pour créer une base savoureuse.',
      },
      {
        title: 'Ajout des Légumes et des Fromages',
        description: 'Disposez les aubergines grillées sur la sauce tomate, puis ajoutez le parmigiano reggiano DOP râpé et des cuillerées de ricotta.',
      },
      {
        title: 'Ajout des Pignons de Pin et de l\'Origan',
        description: 'Saupoudrez des pignons de pin et de l\'origan sur les légumes et les fromages pour ajouter du croquant et de la saveur.',
      },
      {
        title: 'Cuisson au Four',
        description: 'Enfournez la pizza et tournez-la, jusqu’à ce que la croûte soit dorée et que les fromages soient fondus.',
      },
      {
        title: 'Ajout des Garnitures Finales',
        description: 'À la sortie du four, garnissez la pizza avec des feuilles de basilic frais pour une touche de fraîcheur.',
      },
    ],
    'pizza-zucchini': [
      {
        title: 'Préchauffage du Four',
        description: 'Préchauffez votre four à 350°C pendant 20 minutes.',
      },
      {
        title: 'Préparation de la Pâte',
        description: 'Étalez la pâte à pizza de manière homogène sur une surface plane.',
      },
      {
        title: 'Ajout de la Base de Crème Fraîche',
        description: 'Étalez une fine couche de crème fraîche sur la pâte pour une base crémeuse.',
      },
      {
        title: 'Ajout de la Mozzarella et de la Ricotta au Citron',
        description: 'Répartissez la mozzarella fior di latte et des cuillerées de ricotta au citron sur la crème fraîche.',
      },
      {
        title: 'Ajout des Courgettes',
        description: 'Disposez le carpaccio de courgette de manière uniforme sur les fromages.',
      },
      {
        title: 'Cuisson au Four',
        description: 'Enfournez la pizza à 220°C pendant 10-12 minutes, jusqu’à ce que les courgettes soient tendres et que les fromages soient fondus.',
      },
      {
        title: 'Ajout des Garnitures Finales',
        description: 'À la sortie du four, ajoutez le jambon cru italien, la stracciatella des Pouilles, des zestes de citron, des feuilles de basilic frais, et arrosez d’un filet d’huile d’olive vierge extra.',
      },
    ],
  };

  // Convertir le titre de l'URL en une clé valide pour accéder aux étapes de préparation
  const recipeKey = title.toLowerCase().replace(/\s+/g, '-');

  // Obtenir les étapes de préparation pour la recette sélectionnée
  const steps = recipesSteps[recipeKey] || [];

  return (
    <div className="preparation-steps">
      <h1 className="title2">{title.replace(/-/g, ' ')}</h1> {/* Dynamise le titre */}
      <div className="steps-container">
        {steps.length > 0 ? (
          steps.map((step, index) => (
            <div key={index} className="step-item">
              <div className="step-number" style={{ fontSize: '2rem', fontWeight: 'bold', color: 'wheat' }}>{index + 1}</div>
              <h2 className="step-title">{step.title}</h2>
              <p className="step-description">{step.description}</p>
            </div>
          ))
        ) : (
          <p>Aucune étape disponible pour cette recette.</p>
        )}
      </div>
    </div>
  );
};

export default PreparationSteps;
