import React from 'react';
import './ThirdPart.css';
import './SuperfoodCard.css'
import FourImage from '../assets/four2.png';
import FastSvg from '../assets/svg/fast.svg';
import DesignSvg from '../assets/svg/design.svg';
import PizzaCutterSvg from '../assets/svg/pizza-cutter.svg';
import PizzaSvg from '../assets/svg/pizzaSvg.svg';
import FlameSvg from '../assets/svg/flame.svg';
import EasySvg from '../assets/svg/easy.svg';
import BackgroundSvg from '../assets/ThirdPart.svg'; // Import direct du SVG comme image
import Time from '../assets/time.webp';

const ThirdPart = () => {
  return (
    <div className="third-part-container" style={{ backgroundImage: `url(${BackgroundSvg})` }}>
    <h2 className="titleThird">Pourquoi PizzaGO est le meilleur</h2>
  
    <div className="features-container">
      {/* Colonne gauche */}
      <div className="feature-column left">
        <div className="feature-wrapper">
          <img src={PizzaSvg} alt="365j/an" className="feature-icon" />
          <h2 className="feature-title">365j/an</h2>
          <p className="feature-text">Utilisable tous les jours, PizzaGO est l'outil indispensable pour les amateurs de pizza.</p>
        </div>
        <div className="feature-wrapper">
          <img src={FlameSvg} alt="Allumage" className="feature-icon" />
          <h2 className="feature-title">Allumage</h2>
          <p className="feature-text">Allumez votre four en un clin d'œil avec PizzaGO.</p>
        </div>
        <div className="feature-wrapper">
          <img src={FastSvg} alt="Rapidité" className="feature-icon" />
          <h2 className="feature-title">Rapidité</h2>
          <p className="feature-text">PizzaGO cuit vos pizzas en un temps record pour des soirées réussies en famille.</p>
        </div>
      </div>
  
      {/* Image du four au centre */}
      <img src={FourImage} alt="Four" className="four-image" />
  
      {/* Colonne droite */}
      <div className="feature-column right">
        <div className="feature-wrapper">
          <img src={PizzaCutterSvg} alt="100% Lovers" className="feature-icon" />
          <h2 className="feature-title">100% Lovers</h2>
          <p className="feature-text">Parce que la pizza, c'est l'amour, PizzaGO est conçu pour les vrais amoureux de la pizza.</p>
        </div>
        <div className="feature-wrapper">
          <img src={EasySvg} alt="Easy" className="feature-icon" />
          <h2 className="feature-title">Easy</h2>
          <p className="feature-text">PizzaGO est facile à utiliser pour des résultats parfaits à chaque fois.</p>
        </div>
        <div className="feature-wrapper">
          <img src={DesignSvg} alt="Design" className="feature-icon" />
          <h2 className="feature-title">Design</h2>
          <p className="feature-text">Avec son design élégant, PizzaGO s'intègre parfaitement dans toutes les cuisines.</p>
        </div>
      </div>
    </div>
  
    {/* Limited Time Offer Button */}
    <a href="#productShowcase">
    <button className="cta-button third">PROMO 43% MAINTENANT</button>
    </a>
          <p className="limited-offer third">
            <img src={Time} alt="Clock Icon" className="icon1" />
            OFFRE LIMITEE • FREE PELLE & HOUSSE INCLUE
          </p>
     
  </div>
  );
};

export default ThirdPart;
