import React from 'react';
import './GreenPromoCard.css'; // Fichier CSS pour styliser le composant
import GreensImage from '../assets/fille.png'; // Assurez-vous que l'image est dans votre dossier d'assets
import ClockIcon from '../assets/time.webp';
import "./SuperfoodCard.css";

const GreenPromoCard = () => {
  return (
    <div className="greens-promo-wrapper">
      <div className="greens-image-wrapper">
        <img src={GreensImage} alt="Green Drink Promo" className="greens-promo-image" />
      </div>
      <div className="greens-content-wrapper">
        <h1>Créez des pizzas sans contrainte, allumez, faites cuire, éteignez</h1>
        <p className="greens-subtext">PizzaGO vous simplifie la vie en allumant le four. Vous n'avez qu'a garnir vos pizzas, les cuires et éteindre le four.</p>
        <p className="greens-subtext">PizzaGO est fournis avec une pelle ainsi qu'une housse de protection. Vous pouvez retrouver ce four sur les magasins ci-dessous, les produits sont garantie 2 ans.</p>
        <a href="#productShowcase">
        <button className="greens-cta-button">PROMO 43% MAINTENANT</button>
        </a>
        <p className="limited-offer">
          <img src={ClockIcon} alt="Clock Icon" className="icon" />
          LIMITED TIME OFFER • FREE BOTTLE & WHISK INCLUDED
        </p>
      </div>
    </div>
  );
};

export default GreenPromoCard;
