import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './ContactPage.css';

const ContactPage = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p09i41v', 'template_zy54dts', form.current, 'PuRblNLW2ABt3VcCJ')
      .then((result) => {
          console.log(result.text);
          alert("Votre message a été envoyé avec succès !");
      }, (error) => {
          console.log(error.text);
          alert("Une erreur est survenue. Veuillez réessayer.");
      });
  };

  return (
    <div className="elegant-contact-page">
      <div className="contact-header">
        <h1 className="contact-title">Nous Contacter</h1>
        <p className="contact-subtitle">Nous serions ravis de recevoir de vos nouvelles</p>
      </div>

      <div className="contact-container">
        <div className="contact-form">
          <h2 className="form-title">Envoyez-nous un message</h2>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <input type="text" name="user_name" placeholder="Votre Nom" className="elegant-input" required />
            </div>
            <div className="form-group">
              <input type="email" name="user_email" placeholder="Votre Email" className="elegant-input" required />
            </div>
            <div className="form-group">
              <textarea name="message" placeholder="Votre Message" className="elegant-textarea" required></textarea>
            </div>
            <button type="submit" className="elegant-button">Envoyer le Message</button>
          </form>
        </div>

        <div className="contact-info">
          <h2 className="info-title">Coordonnées</h2>
          <div className="info-content">
            <p>Téléphone: +33 6 61 97 11 92</p>
            <p>Adresse: Le Gardénia, 13122 Aix en provence, France</p>
          </div>
        </div>
      </div>
      
      <div className="footer">
        <p>© 2024 Pizzago. Tous droits réservés.</p>
      </div>
    </div>
  );
};

export default ContactPage;
