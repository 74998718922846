import React from "react";
import "./Home.css";
import SuperfoodCart from "./SuperfoodCard";
import Marques from "./Marques";
import GreenPromoCard from "./GreenPromoCard";
import ThirdPart from "./ThirdPart";
import Testimonials from "./Testimonials";
import Reviews from "./Reviews";
import HowToUse from "./HowToUse";
import Product from "./ProductShowcase";
import RecipeList from "./RecipeList";
import VideoPlayer from './VideoPlayer'

const Home = () => {
  return (
    <>
      <SuperfoodCart />

      <GreenPromoCard />
      <ThirdPart />
      <Testimonials />
      <Reviews />
      <RecipeList />
      <HowToUse />
      <Product />
      <VideoPlayer/>
    </>
  );
};

export default Home;
