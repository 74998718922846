// src/components/Four.js
import React from 'react';
import { useCart } from './CartContext'; // Assurez-vous que le chemin est correct
import './ProductDisplayPage.css';


const Four = () => {
  const { addToCart } = useCart();

  // Définir les produits avec leurs IDs de prix Stripe
  const products = [
    { 
      id: 1, 
      image: require('../assets/FourElectrique.webp'),
      title: 'Four à pizza classique', 
      price: 99, 
      stripePriceId: 'prod_QkzqkgBUb5Rhw7' // ID de prix Stripe
    },
    { 
      id: 2, 
      image: require('../assets/FourElec.png'),
      title: 'Four Electrique', 
      price: 199, 
      stripePriceId: 'prod_QkzqkgBUb5Rhw7' // ID de prix Stripe
    },
    { 
      id: 3, 
      image: require('../assets/four-bois.png'),
      title: 'Four à bois', 
      price: 299, 
      stripePriceId: 'prod_QkzqkgBUb5Rhw7' // ID de prix Stripe
    },
    // Ajoutez d'autres produits ici
  ];

  return (
    <div className="unique-display-page">
      <h2 className="unique-page-title">Fours à pizza</h2>
      <div className="unique-products-grid">
        {products.map((product) => (
          <div key={product.id} className="custom-card-container">
            <div className="custom-image-wrapper">
              <img src={product.image} alt={product.title} className="custom-product-image" />
            </div>
            <div className="custom-card-details">
              <h3 className="custom-product-title">{product.title}</h3>
              <div className="custom-product-price">{product.price} €</div>
              <button
                className="custom-add-to-cart-button"
                onClick={() => addToCart(product)}
              >
                Ajouter au panier
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Four;
