// src/components/CartSidebar.js
import React from 'react';
import './CartSidebar.css';
import { useCart } from './CartContext';
import QuantitySelector from './QuantitySelector'; // Importer le composant mis à jour
import offre1 from '../assets/offre1.png';

const recommendedProducts = [
  // Exemple de produits recommandés
  {
    id: '3',
    image: offre1,
    title: 'Farine',
    price: 29.99,
  },
  {
    id: '4',
    image: 'path/to/product4.jpg',
    title: 'Produit Recommandé 2',
    price: 19.99,
  },
  // Ajoutez d'autres produits recommandés ici
];

const CartSidebar = () => {
  const { cartItems, removeFromCart, clearCart, updateCartItem, addToCart, isCartOpen, closeCartSidebar } = useCart();

  // Calcul du sous-total
  const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

  // Exemple de frais de livraison
  const freeShippingThreshold = 50.00;
  const shippingCost = subtotal >= freeShippingThreshold ? 0 : 5.95;
  const remainingForFreeShipping = Math.max(freeShippingThreshold - subtotal, 0).toFixed(2);
  const shippingMessage = remainingForFreeShipping > 0
    ? `Encore ${remainingForFreeShipping} € et nous expédions sans frais de port`
    : "Livraison gratuite!";

  // Calcul du total général
  const total = (parseFloat(subtotal) + shippingCost).toFixed(2);

  return (
    <div className={`cart-sidebar ${isCartOpen ? 'open' : ''}`}>
      <button className="cart-toggle-button" onClick={closeCartSidebar}>
        Fermer
      </button>

      {/* Section des produits recommandés */}
      <div className="recommended-products-section">
        <h2>Ajoutez à votre commande</h2>
        <ul className="recommendation-list">
          {recommendedProducts.map((product) => (
            <li key={product.id} className="recommendation-item">
              <img src={product.image} alt={product.title} className="recommendation-image" />
              <div className="recommendation-details">
                <h3>{product.title}</h3>
                <p>{product.price.toFixed(2)} €</p>
                <button className="add-recommendation-button" onClick={() => addToCart(product)}>
                  Ajouter
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Section du récapitulatif du panier */}
      <div className="cart-content">
        <h2>Votre Panier</h2>
        {cartItems.length === 0 ? (
          <p>Votre panier est vide.</p>
        ) : (
          <>
            <ul className="cart-items-list">
              {cartItems.map((item, index) => (
                <li key={index} className="cart-item">
                  <div className="cart-item-details">
                    <img src={item.image} alt={item.title} className="cart-item-image" />
                    <div>
                      <h3>{item.title}</h3>
                      <div className="cart-item-controls">
                        <QuantitySelector
                          quantity={item.quantity}
                          onChange={(newQuantity) => updateCartItem(item.id, newQuantity)}
                        />
                      </div>
                      <span className="cart-item-price">{(item.price * item.quantity).toFixed(2)} €</span>
                    </div>
                  </div>
                  <button className="remove-item-button" onClick={() => removeFromCart(item.id)}>&times;</button>
                </li>
              ))}
            </ul>
            <div className="cart-summary">
              <p>Sous-total: <span>{subtotal} €*</span></p>
              <p>Frais de livraison: <span>{shippingCost.toFixed(2)} €</span></p>
              <p className="shipping-message">{shippingMessage}</p>
              <p className="total-amount">Total: <span>{total} €</span></p>
              <button className="checkout-button">Procéder au paiement</button>
              <button className="clear-cart-button" onClick={clearCart}>Vider le panier</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartSidebar;
