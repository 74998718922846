import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import cartIcon from '../assets/cart.svg';
import CartDropdown from './CartDropdown'; // Import du nouveau composant CartDropdown

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
  };

  // Fermer le menu si on clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="logo-container">
        <div className="logo">PizzaGO</div>
        <div className="mini-oven">
          <div className="flame"></div>
        </div>
      </div>
      <nav ref={menuRef}>
        <ul className={isMenuOpen ? 'showing' : ''}>
          <li className="logo">
            <Link to="/" onClick={closeMenu}>Accueil</Link>
          </li>
          
       
<li className="dropdown">
    <Link to="#" onClick={toggleDropdown}>Produits</Link>
    <ul className={`dropdown-content logo ${isDropdownOpen ? 'show' : ''}`}>
        <li><Link to="/fours" onClick={closeMenu}>Four</Link></li>
        <li><Link to="/pelles" onClick={closeMenu}>Pelles</Link></li>
        <li><Link to="/alimentaire" onClick={closeMenu}>Alimentaire</Link></li>
        <li><Link to="/accessoires" onClick={closeMenu}>Accessoires</Link></li>
    </ul>
</li>

          <li className="logo"><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
        </ul>
      </nav>
      <div className="cart-icon-container">
        <img src={cartIcon} alt="Panier" className="cart-icon" onClick={toggleCart} />
        {isCartOpen && <CartDropdown />} {/* Utilisation du nouveau composant CartDropdown */}
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>☰</div>
    </header>
  );
};

export default Header;
