// src/components/CustomProductCard.js
import React from 'react';
import './ProductDisplayPage.css';

const CustomProductCard = ({ image, title, price, rating, reviews, addToCart }) => (
  <div className="custom-card-container">
    <div className="custom-image-wrapper">
      <img src={image} alt={title} className="custom-product-image" />
    </div>
    <div className="custom-card-details">
      <h3 className="custom-product-title">{title}</h3>
      <div className="custom-product-rating">
        <span className="custom-rating-stars">★★★★☆</span> {/* Exemple de notation par étoiles */}
        <span className="custom-reviews">({reviews})</span>
      </div>
      <div className="custom-product-price">
        <span className="custom-price">{price} €*</span>
      </div>
      <button className="custom-add-to-cart-button" onClick={addToCart}>
        Ajouter au panier
      </button>
    </div>
  </div>
);

export default CustomProductCard;
