import React, { useState } from 'react';
import './ImageSlider.css'; // Assurez-vous de créer ce fichier CSS

const ImageSlider = ({ images, discountTag }) => {
  const [currentImage, setCurrentImage] = useState(0);

  // Fonctions pour naviguer entre les images
  const prevImage = () => {
    setCurrentImage((currentImage + images.length - 1) % images.length);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  return (
    <div className="image-slider-container">
      <div className="discount-tag">{discountTag}</div>
      <img src={images[currentImage]} alt={`Product ${currentImage}`} className="main-image" />
      
      <div className="thumbnail-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={`thumbnail ${currentImage === index ? 'active' : ''}`}
            onClick={() => setCurrentImage(index)}
          />
        ))}
      </div>

      {/* Boutons de navigation avec des flèches SVG */}
      <div className="navigation">
        <button className="nav-button prev" onClick={prevImage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"/>
          </svg>
        </button>
        <button className="nav-button next" onClick={nextImage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
