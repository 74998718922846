import React from 'react';
import './Testimonials.css';
import "./SuperfoodCard.css";
import profile1 from '../assets/profil2.jpeg'; 
import profile2 from '../assets/val.jpg';// Remplacez par les images réelles


const Testimonials = () => {
  return (
    <div className="testimonials-section">
      <div className="testimonial-card">
        <img src={profile1} alt="Lana C." className="profile-image" />
        <div className="testimonial-content">
          <div className="rating">
            <span>⭐</span><span>⭐</span><span>⭐</span><span>⭐</span><span>⭐</span>
          </div>
          <p className="testimonial-text">
          Cela fait maintenant trois mois que je l'ai, j'utilise ce four de temps en temps, je l'allume 20m avant. Je prépare mes pizzas et grâce à la rotation j'obtiens une pizza bien cuite.
          </p>
          <p className="testimonial-author">Antony V.</p>
          <p className="verified-buyer">✔ Acheteur vérifié</p>
        </div>
      </div>

      <div className="testimonial-card">
        <img src={profile2} alt="Lana C." className="profile-image" />
        <div className="testimonial-content">
          <div className="rating">
            <span>⭐</span><span>⭐</span><span>⭐</span><span>⭐</span><span>⭐</span>
          </div>
          <p className="testimonial-text">
          Je peux vous recommander ce four à pizza ! Il est facile à utiliser, chauffe rapidement, et les pizzas sont tout simplement délicieuses. Cela change des Barbecues et j'aime bien faire le pizzaiolo.
          </p>
          <p className="testimonial-author">Valentin F.</p>
          <p className="verified-buyer">✔ Acheteur vérifié</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
