import React from 'react';
import './Reviews.css'; // N'oubliez pas de créer ce fichier CSS
import "./SuperfoodCard.css";
import Time from "../assets/time.webp";

const reviewsData = [
    {
      text: "En tant qu'amateur de cuisine italienne, je recommande ce four. La cuisson est rapide et uniforme, et la croûte est toujours croustillante, comme dans les meilleures pizzerias. J'aime aussi expérimenter avec différentes garnitures, et ce four fait ressortir toutes les saveurs. C’est un must pour tous les passionnés de pizza comme moi !",
      author: "Greg",
      role: "Acheteur vérifié",
    },
    {
      text: "Je ne l'utilise pas tous les jours, mais quand je reçois des amis, ce four à pizza devient l'attraction de la soirée. Il est tellement simple à utiliser que même mes invités se mettent à la cuisine ! Les pizzas sont prêtes rapidement, et tout le monde s'amuse à préparer la sienne avec ses garnitures.",
      author: "Leo",
      role: "Acheteur vérifié",
    },
    {
      text: "Avec deux enfants à la maison, je cherchais un moyen ludique de préparer des repas rapides, et ce four à pizza a été une bonne chose. Il chauffe rapidement et cuit les pizzas en quelques minutes, ce qui est parfait. Mes enfants adorent personnaliser leurs pizzas, et moi, c'est devenu notre petit rituel familial !",
      author: "Angie",
      role: "Acheteur vérifié",
    },
    {
      text: "Ce qui m'a vraiment séduit avec ce four à pizza, c'est son design moderne et sa facilité d'utilisation",
      author: "François",
      role: "Acheteur vérifié",
    },
    {
      text: "J'ai essayé des recettes de pizzas sucrées, des pizzas au fromage de chèvre et figues, et même des variantes asiatiques ! Le résultat est toujours bluffant. Pour les petits pizzaiolo, c'est l'outil rêvé.",
      author: "Samantha",
      role: "Acheteur vérifié",
    },
    {
      text: "Ce four à pizza est tout simplement génial ! Depuis que je l'ai, les pizzas maison sont devenues meilleurs.",
      author: "Jean",
      role: "Acheteur vérifié",
    },
  ];

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h2 className="reviews-title">Découvrez pourquoi les clients se sentent bien avec PizzaGO</h2>
      <div className="reviews-grid">
        {reviewsData.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-stars">★★★★★</div>
            <p className="review-text">"{review.text}"</p>
            <p className="review-author">
              {review.author} <span className="review-role">• {review.role}</span>
            </p>
          </div>
        ))}
      </div>
      <a href="#productShowcase">
          <button  className="cta-button">PROMO 43% MAINTENANT</button>
          </a>
          <p className="limited-offer">
            <img src={Time} alt="Clock Icon" className="icon1" />
            OFFRE LIMITEE • FREE PELLE & HOUSSE INCLUE
          </p>
    </div>
  );
};

export default Reviews;
