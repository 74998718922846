// src/components/QuantitySelector.js
import React from 'react';
import './QuantitySelector.css'; // Assurez-vous que ce fichier existe et est correctement stylisé

const QuantitySelector = ({ quantity, onChange }) => (
  <input
    type="number"
    min="1"
    value={quantity}
    onChange={e => onChange(Number(e.target.value))}
    className="quantity-input"
  />
);

export default QuantitySelector;
