import React from 'react';
import ProductDisplayPage from './ProductDisplayPage';

const products = [
  { image: 'path/to/image10.jpg', title: 'Coupe-pizza', price: '9.99' },
  { image: 'path/to/image11.jpg', title: 'Thermomètre de four', price: '15.99' },
  { image: 'path/to/image12.jpg', title: 'Pierre à pizza', price: '19.99' },
];

const Accessoires = () => {
  return <ProductDisplayPage products={products} />;
};

export default Accessoires;
