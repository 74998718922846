import React from 'react';
import RecipeItem from './RecipeItem';
import './RecipeList.css';
import "./SuperfoodCard.css";
import { Link } from 'react-router-dom';
import pizzaImage from '../assets/249.jpg';
import pizzaImage2 from '../assets/251.jpg';
import pizzaImage3 from '../assets/122.jpg';
import pizzaImage4 from '../assets/247.jpg';
import Time from '../assets/time.webp';

const RecipeList = () => {
  const generateLinkFromTitle = (title) => {
    return `/preparation/${title.toLowerCase().replace(/\s+/g, '-')}`;
  };

  const recipes = [
    { image: pizzaImage, title: 'Pizza Copa Abricot', prepTime: '5mn', level: 'Facile' },
    { image: pizzaImage2, title: 'Pizza Zucchini', prepTime: '6mn', level: 'Facile' },
    { image: pizzaImage3, title: 'Pizza Pistache', prepTime: '7mn', level: 'Facile' },
    { image: pizzaImage4, title: 'Pizza Végétarienne', prepTime: '6mn', level: 'Facile' },
  ]

  return (
    <div className="recipe-container">
      <h4 className="recipes-title">Des recettes uniques.</h4>
      <div className="recipe-list">
        {recipes.map((recipe, index) => (
          <Link to={generateLinkFromTitle(recipe.title)} key={index}>
            <RecipeItem
              image={recipe.image}
              title={recipe.title}
              prepTime={recipe.prepTime}
              level={recipe.level}
            />
          </Link>
        ))}
      </div>
      <div className="cta-container3">
      <a href="#productShowcase">
        <button className="cta-button3">PROMO 43% MAINTENANT</button>
        </a>
        <p className="limited-offer">
          <img src={Time} alt="Clock Icon" className="icon1" />
          OFFRE LIMITEE • PELLE & HOUSSE INCLUE
        </p>
      </div>
    </div>
    
  );
};

export default RecipeList;
