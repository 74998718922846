import React from 'react';
import ProductDisplayPage from './ProductDisplayPage';

const products = [
  { image: 'path/to/image7.jpg', title: 'Sauce tomate', price: '5' },
  { image: 'path/to/image8.jpg', title: 'Mozzarella', price: '4.5' },
  { image: 'path/to/image9.jpg', title: 'Basilic frais', price: '2' },
];

const Alimentaire = () => {
  return <ProductDisplayPage products={products} />;
};

export default Alimentaire;
