import React from 'react';
import './HowToUse.css'; 
import "./SuperfoodCard.css";// N'oubliez pas de créer ce fichier CSS
import Pelle from '../assets/pelle.png'
import PizzaRoller from '../assets/svg/pizza-roller-noir.svg'
import Four1 from '../assets/four.svg'
import Time from '../assets/time.webp';

const HowToUse = () => {
  return (
    <div className="how-to-use-container">
      <h2 className="how-to-use-title">Comment ça marche ?</h2>
      <div className="how-to-use-items">
        <div className="how-to-use-item">
          <div className="icon-circle">
            {/* Utilisez vos icônes SVG ou des images ici */}
            <img className='pizza-roller' src={PizzaRoller} alt="Mix with cold water" />
          </div>
          <p>Préparez la pâte</p>
        </div>
        <div className="how-to-use-item">
          <div className="icon-circle">
            <img className='pelle' src={Pelle} alt="Blend into smoothies" />
          </div>
          <p>Enfournez</p>
        </div>
        <div className="how-to-use-item">
          <div className="icon-circle">
            <img src={Four1} alt="Sprinkle over salads" />
          </div>
          <p>Laissez cuire 2 min.</p>
        </div>
      </div>
      <a href="#productShowcase">
          <button  className="cta-button">PROMO 43% MAINTENANT</button>
          </a>
          <p className="limited-offer">
            <img src={Time} alt="Clock Icon" className="icon1" />
            OFFRE LIMITEE • FREE PELLE & HOUSSE INCLUE
          </p>
    </div>
  );
};

export default HowToUse;
