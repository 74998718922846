import React from "react";
import "./SuperfoodCard.css"; // Le fichier CSS pour styliser le composant
import Stars from "../assets/stars.png";
import Trust from "../assets/trust.webp";
import Test2 from "../assets/test2.png";
import Time from "../assets/time.webp";
import Rocket from '../assets/pizza1.svg'
import Restaurant from '../assets/restaurant.svg'
import Stone from '../assets/stone.svg'



const SuperfoodCard = () => {


  return (
    <div className="container">
      <div className="left-container">
        {/* Contenu du premier container */}
        <img
          src={Test2}
          alt="Rheal Superfoods Clean Greens"
          className="product-image"
        />
      </div>
      <div className="right-container">
        <div className="trustpilot">
          <span className="excellent">Excellent</span>
          <img
            src={Stars}
            alt="TrustPilot Rating"
            className="trustpilot-stars"
          />
          <span className="reviews">70 reviews on </span>
          <img src={Trust} alt="TrustPilot Logo" className="trustpilot-logo" />
        </div>
        <div className="text-content">
          <div className="badgeFirst">#PizzaTime</div>
          <h1>Créer ta première pizza.</h1>
          <p className="description">
            Des pizzas maison parfaites en quelques minutes avec notre four à
            pizza!
          </p>
          <ul className="avantages">
          <li>
              <img src={Rocket} alt="Rocket Icon" style={{ width: '20px', marginRight: '10px' }} />
              Cuit vos pizzas en quelques minutes seulement.
            </li>
            <li>
            <img src={Restaurant} alt="Rocket Icon" style={{ width: '20px', marginRight: '10px' }} />
              Obtenez des pizzas au goût authentique et croustillant comme au
              restaurant.
            </li>
            <li>
            <img src={Stone} alt="Rocket Icon" style={{ width: '20px', marginRight: '10px' }} />
              Idéal pour cuire non seulement des pizzas, mais aussi des
              pains, tartes, et bien plus.
            </li>
          </ul>
          <a href="#productShowcase">
          <button  className="cta-button">PROMO 43% MAINTENANT</button>
          </a>
          <p className="limited-offer">
            <img src={Time} alt="Clock Icon" className="icon1" />
            OFFRE LIMITEE • FREE PELLE & HOUSSE INCLUE
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuperfoodCard;
