import React from 'react';
import deliveryIcon from '../assets/delivery-vert.svg'; // Assurez-vous que le chemin est correct

const DeliveryEstimate = () => {
  const calculateEstimatedDelivery = () => {
    const currentDate = new Date();
    let deliveryDate = new Date(currentDate);
    let addedDays = 0;

    // Ajouter des jours ouvrables (exclure les samedis et dimanches)
    while (addedDays < 3) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);
      const dayOfWeek = deliveryDate.getDay();
      // 0 = Dimanche, 6 = Samedi (on saute ces jours)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        addedDays++;
      }
    }

    return deliveryDate.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    });
  };

  return (
    <small>
      <img 
        src={deliveryIcon} 
        alt="Icône de livraison" 
        style={{ width: '30px', height: '30px', marginRight: '5px', verticalAlign: 'middle' }} 
      />
      Livraison estimée sous 3 jours ouvrables : {calculateEstimatedDelivery()}
    </small>
  );
};

export default DeliveryEstimate;
