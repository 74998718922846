import React from "react";
import ProductDisplayPage from "./ProductDisplayPage";

const products = [
  {       image: require('../assets/pelle1.png'), title: "Pelle à pizza inox ", price: "29" },
  {       image: require('../assets/pelle2.png'), title: "Pelle rond en inox", price: "39" },

];

const Pelles = () => {
  return <ProductDisplayPage products={products} />;
};

export default Pelles;
