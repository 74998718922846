// src/components/ProductDisplayPage.js
import React  from 'react';
import './ProductDisplayPage.css';
import fondvert from '../assets/fondvert.svg';
import CustomProductCard from './CustomProductCard';
import { useCart } from './CartContext'; // Correctement utiliser useCart

const ProductDisplayPage = ({ products }) => {
  const { addToCart, openCartSidebar } = useCart(); // Utiliser useCart ici

  const handleAddToCart = (product) => {
    addToCart(product); // Ajoute le produit au panier
    openCartSidebar(); // Ouvre la barre latérale du panier
  };

  return (
    <div className="unique-display-page" style={{ backgroundImage: `url(${fondvert})` }}>
      <h2 className="unique-page-title">Des spécialités italiennes pour votre dolce vita</h2>
      <div className="unique-products-grid">
        {products.map((product, index) => (
          <CustomProductCard 
            key={index} 
            {...product} 
            addToCart={() => handleAddToCart(product)} // Passe la fonction addToCart avec le produit spécifique
          />
        ))}
      </div>
    </div>
  );
};

export default ProductDisplayPage;
